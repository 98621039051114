import { AreaBalanceHeaderComponent } from "./area-balance-header/area-balance-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletMarkerClusterModule } from "@asymmetrik/ngx-leaflet-markercluster";
import { UiSwitchModule } from "ngx-toggle-switch";
import { Ng5SliderModule } from "ng5-slider";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxEchartsModule } from "ngx-echarts";
import { NgSelectConfig, NgSelectModule, ɵs } from "@ng-select/ng-select";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CronEditorModule } from "ngx-cron-editor";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";

import { EchartComponent } from "./echart/echart.component";
import { ProcessInfoComponent } from "./process-info/process-info.component";
import { ClusterRelayActionComponent } from "./cluster-relay-action/cluster-relay-action.component";
import { GroupBillingTableComponent } from "./group-billing-table/group-billing-table.component";
import { BtnLoadingComponent } from "src/app/shared/components/btn-loading/btn-loading.component";
import { FeatherIconComponent } from "src/app/shared/components/feather-icon/feather-icon.component";
import { SharedPipesModule } from "src/app/shared/pipes/shared-pipes.module";
import { SearchModule } from "src/app/shared/components/search/search.module";
import { SharedDirectivesModule } from "src/app/shared/directives/shared-directives.module";
import { LayoutsModule } from "src/app/shared/components/layouts/layouts.module";
import { MeterTableComponent } from "src/app/shared/components/meter-table/meter-table.component";
import { MeterTableComissionedComponent } from "src/app/shared/components/meter-table-comissioned/meter-table-comissioned.component";
import { MeterTableNotComissionedComponent } from "src/app/shared/components/meter-table-not-comissioned/meter-table-not-comissioned.component";
import { MeterTableUnregisteredComponent } from "src/app/shared/components/meter-table-unregistered/meter-table-unregistered.component";
import { UserTableComponent } from "src/app/shared/components/user-table/user-table.component";
import { TableLoadingComponent } from "src/app/shared/components/table-loading/table-loading.component";
import { SmcTableComponent } from "src/app/shared/components/smc-table/smc-table.component";
import { EnergyTableComponent } from "src/app/shared/components/energy-table/energy-table.component";
import { ModemInfoComponent } from "src/app/shared/components/modem-info/modem-info.component";
import { ButtonsRelayOperationComponent } from "src/app/shared/components/buttons-relay-operation/buttons-relay-operation.component";
import { MeterInfoComponent } from "src/app/shared/components/meter-info/meter-info.component";
import { AlarmTableComponent } from "src/app/shared/components/alarm-table/alarm-table.component";
import { DatetimePickerComponent } from "src/app/shared/components/datetime-picker/datetime-picker.component";
import { TableEventLogComponent } from "src/app/shared/components/table-event-log/table-event-log.component";
import { CardStatusComponent } from "src/app/shared/components/card-status/card-status.component";
import { CardInfoComponent } from "src/app/shared/components/card-info/card-info.component";
import { TicketTableComponent } from "src/app/shared/components/ticket-table/ticket-table.component";
import { RollBackComponent } from "src/app/shared/components/roll-back/roll-back.component";
import { RadioLogTableComponent } from "src/app/shared/components/radio-log-table/radio-log-table.component";
import { CardTableComponent } from "src/app/shared/components/card-table/card-table.component";
import { ChangePeriodComponent } from "src/app/shared/components/change-period/change-period.component";
import { TimePickerComponent } from "src/app/shared/components/time-picker/time-picker.component";
import { MapsComponent } from "src/app/shared/components/maps/maps.component";
import { InstantMeasureComponent } from "src/app/shared/components/instant-measure/instant-measure.component";
import { RequestEnergyComponent } from "src/app/shared/components/request-energy/request-energy.component";
import { SearchBarComponent } from "src/app/shared/components/search-bar/search-bar.component";
import { FrozenDataComponent } from "src/app/shared/components/frozen-data/frozen-data.component";
import { RequestDataComponent } from "src/app/shared/components/request-data/request-data.component";
import { QualityIndicatorsComponent } from "src/app/shared/components/quality-indicators/quality-indicators.component";
import { SmcNotRegistredTableComponent } from "src/app/shared/components/smc-not-registred-table/smc-not-registred-table.component";
import { GroupsTableComponent } from "src/app/shared/components/groups-table/groups-table.component";
import { GroupHeaderComponent } from "src/app/shared/components/group-header/group-header.component";
import { TagsTableComponent } from "src/app/shared/components/tags-table/tags-table.component";
import { QualityIncatorsButtonComponent } from "src/app/shared/components/quality-incators-button/quality-incators-button.component";
import { BillingTableComponent } from "src/app/shared/components/billing-table/billing-table.component";
import { RequestFrozenDayaComponent } from "src/app/shared/components/request-frozen-data/request-frozen-data.component";
import { SearchTableComponent } from "src/app/shared/components/search-table/search-table.component";
import { PrimaryButtonComponent } from "src/app/shared/components/primary-button/primary-button.component";
import { ServicesNotesTableComponent } from "src/app/shared/components/services-notes-table/services-notes-table.component";
import { FrozenDataUploadTableComponent } from "src/app/shared/components/frozen-data-upload-table/frozen-data-upload-table.component";
import { QualityIndicatorsUploadComponent } from "src/app/shared/components/quality-indicators-upload/quality-indicators-upload.component";
import { EnergyTableUploadComponent } from "src/app/shared/components/energy-table-upload/energy-table-upload.component";
import { QualityIndicatorsMonthlyComponent } from "src/app/shared/components/quality-indicators-monthly/quality-indicators-monthly.component";
import { GroupMetersTableComponent } from "src/app/shared/components/group-meters-table/group-meters-table.component";
import { ProgressIndicatorModalComponent } from "src/app/shared/components/progress-indicator-modal/progress-indicator-modal.component";
import { FirmwareTableComponent } from "src/app/shared/components/firmware-table/firmware-table.component";
import { KeysTableComponent } from "src/app/shared/components/keys-table/keys-table.component";
import { SmcModelsTableComponent } from "src/app/shared/components/smc-models-table/smc-model-table.component";
import { MetersModelsTableComponent } from "src/app/shared/components/meter-models-table/meter-models-table.component";
import { RegistersTableComponent } from "src/app/shared/components/registers-table/registers-table.component";
import { MeterRegisterTableComponent } from "./meter-register-table/meter-register-table.component";
import { MeterDateInfoComponent } from "./meter-date-info/meter-date-info.component";
import { ChipsMultiSelectComponent } from "./chips-multi-select/chips-multi-select.component";
import { CriticAlertsComponent } from "./critic-alerts/critic-alerts.component";
import { RateTypesTableComponent } from "./rate-types-table/rate-types-table.component";
import { SmcRegisterTableComponent } from "./smc-table-register/smc-table-register.component";
import { OpenTheDoorsComponent } from "./btn-open-the-doors/btn-open-the-doors.component";
import { TransformersTableComponent } from "./transformers-table/transformers-table.component";
import { BalanceHeaderComponent } from "./balance-header/balance-header.component";
import { TransformerInfoComponent } from "src/app/shared/components/transformer-info/transformer-info.component";
import { TransmorferMeterTableComponent } from "./transmorfer-meter-table/transformer-meter-table.component";
import { BalanceLineChartComponent } from "./balance-line-chart/balance-line-chart.component";
import { BalanceTableComponent } from "./balance-table/balance-table.component";
import { SingleDeviceBalanceComponent } from "./single-device-balance/single-device-balance.component";
import { AreaTransformersTableComponent } from "src/app/shared/components/area-transformers-table/area-transformers-table.component";
import { TransformerGroupTableComponent } from "./transformer-group-table/transformer-group-table.component";
import { AreaBalanceTableComponent } from "src/app/shared/components/area-balance-table/area-balance-table.component";
import { RegionsTableComponent } from "./regions-table/regions-table.component";
import { RegionCardInfoComponent } from "./region-card-info/region-card-info.component";
import { RegionAreasTableComponent } from "src/app/shared/components/region-areas-table/region-areas-table.component";
import { RegionBalanceHeaderComponent } from "src/app/shared/components/region-balance-header/region-balance-header.component";
import { RegionBalanceTableComponent } from "src/app/shared/components/region-balance-table/region-balance-table.component";
import { MetersFileUploadTableComponent } from "src/app/shared/components/meters-file-upload-table/meters-file-upload-table.component";
import { RecoveyEnergyComponent } from "./recovey-energy/recovey-energy.component";
// SERVICES
import {
  MatFormFieldModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatStepperModule,
} from "@angular/material";
import { MetersAllTableComponent } from "./meters-all-table/meters-all-table.component";
import { CopyButtonComponent } from "./copy-button/copy-button.component";

import { DashBOardFilterComponent } from "./dashboard-filter/dashboard-filter.component";
import { ReadingTableComponent } from "./reading-table/reading-table.component";
import { DownloadPDFComponent } from "./download-pdf/download-pdf.component";
import { GroupInfoCardComponent } from "./group-info-card/group-info-card.component";
import { AppLastCommandCard } from "./last-command-card/last-command-card.component";
import { ScheduledCommandsTableComponent } from "./scheduled-commands-table/scheduled-commands.component";
import { AddRoutineStepComponent } from "./add-routine-step/add-routine-step.component";
import { RoutineDetailsCardComponent } from "./routine-details-card/routine-details-card.component";
import { CustomMatPaginatorIntl } from "src/app/core/interfaces/paginator-label-intl";
import { VoltageAlarmsTableComponent } from "./voltage-alarms-table/voltage-alarms.component";
import { DicFicRecoveryComponent } from "./dic-fic-recovery/dic-fic-recovery.component";
import { DicFicImportTableComponent } from "./dic-fic-import-table/dic-fic-import-table.component";
import { DicFicMonthlyComponent } from "./dic-fic-monthly/dic-fic-monthly.component";
import { CronEditorComponent } from "./cron-editor/cron-editor.component";
import { VoltageOutAlarmResponseComponent } from "./voltage-out-alarm-response/voltage-out-alarm-response.component";
import { AllAlertsTableComponent } from "./all-alerts-table/all-alerts-table.component";
import { AlertStatisticsComponent } from "./alert-statistics/alert-statistics.component";
import { AlertsDashboardFiltersComponent } from "./alerts-dashboard-filters/alerts-dashboard-filters.component";
import { SignalInfoComponent } from "./signal-info/signal-info.component";
import { SingleAlarmTypeTableComponent } from "./single-alarm-type-table/single-alarm-type-table.component";
import { AuditoriaTableComponent } from "src/app/shared/components/auditoria-table/auditoria-table.component";
import { InstallationTableComponent } from "./installation-table/installation-table.component";
import { SmcFileUploadTableComponent } from "./smc-file-upload-table/smc-file-upload-table.component";
import { ParameterizeProcessTableComponent } from "./parameterize-process-table/parameterize-process-table.component";
import { NavHorizontalComponent } from "./nav-horizontal/nav-horizontal.component";
import { ClockTableComponent } from "./clock-table/clock-table.component";
import { ParameterizeTicketTableComponent } from "./parameterize-tickets-table/parameterize-tickets-table.component";
import { SpecialDaysTableComponent } from "./special-days-table/special-days-table.component";
import { ActiveCalendarTableComponent } from "./active-calendar/active-calendar-table.component";
import { DmcrSwitchTableComponent } from "./dmcr-switch-table/dmcr-switch-table.component";
import {BillingPeriodTableComponent} from './billing-period-table/billing-period-table.component';
import { CardCommandComponent } from "./card-command/card-command.component";
import { MeterDMCRProfileTableComponent } from "./dmcr-calendar/dmcr-calendar.component";
import { FiscalComponent } from "./fiscal-table/fiscal-table.component";
import { GeneralReadingsComponent } from "./general-readings/general-readings.component";
import { DemandAndGeneralReadingsComponent } from "./demand-and-general-readings/demand-and-general-readings.component";
import { AuditQualityIndicatorsComponent } from "./quality-indicators-audit/quality-indicators.component";
import { ConsumerInfoComponent } from "./consumer-info/consumer-info.component";

const components = [
  SingleAlarmTypeTableComponent,
  SmcFileUploadTableComponent,
  AuditoriaTableComponent,
  InstallationTableComponent,
  AllAlertsTableComponent,
  AlertStatisticsComponent,
  AlertsDashboardFiltersComponent,
  DicFicMonthlyComponent,
  SignalInfoComponent,
  VoltageOutAlarmResponseComponent,
  DicFicRecoveryComponent,
  SmcRegisterTableComponent,
  BtnLoadingComponent,
  FeatherIconComponent,
  MeterTableComponent,
  MeterTableComissionedComponent,
  MeterTableNotComissionedComponent,
  UserTableComponent,
  TableLoadingComponent,
  SmcTableComponent,
  CriticAlertsComponent,
  EnergyTableComponent,
  RateTypesTableComponent,
  ModemInfoComponent,
  ButtonsRelayOperationComponent,
  MeterInfoComponent,
  AlarmTableComponent,
  MapsComponent,
  DatetimePickerComponent,
  ChipsMultiSelectComponent,
  TableEventLogComponent,
  CardStatusComponent,
  CardInfoComponent,
  TicketTableComponent,
  ParameterizeProcessTableComponent,
  SpecialDaysTableComponent,
  OpenTheDoorsComponent,
  RollBackComponent,
  RadioLogTableComponent,
  CardTableComponent,
  TimePickerComponent,
  ChangePeriodComponent,
  InstantMeasureComponent,
  RequestEnergyComponent,
  SearchBarComponent,
  FrozenDataComponent,
  RequestDataComponent,
  QualityIndicatorsComponent,
  SmcNotRegistredTableComponent,
  RequestFrozenDayaComponent,
  GroupsTableComponent,
  MeterTableUnregisteredComponent,
  GroupHeaderComponent,
  TagsTableComponent,
  SearchTableComponent,
  QualityIncatorsButtonComponent,
  BillingTableComponent,
  PrimaryButtonComponent,
  ServicesNotesTableComponent,
  FrozenDataUploadTableComponent,
  QualityIndicatorsUploadComponent,
  EnergyTableUploadComponent,
  QualityIndicatorsMonthlyComponent,
  GroupMetersTableComponent,
  ProgressIndicatorModalComponent,
  FirmwareTableComponent,
  KeysTableComponent,
  SmcModelsTableComponent,
  MetersModelsTableComponent,
  RegistersTableComponent,
  MeterRegisterTableComponent,
  MeterDateInfoComponent,
  GroupBillingTableComponent,
  BalanceHeaderComponent,
  TransformersTableComponent,
  TransformerInfoComponent,
  TransmorferMeterTableComponent,
  BalanceLineChartComponent,
  BalanceTableComponent,
  SingleDeviceBalanceComponent,
  ClusterRelayActionComponent,
  ProcessInfoComponent,
  EchartComponent,
  TransformerGroupTableComponent,
  AreaTransformersTableComponent,
  AreaBalanceHeaderComponent,
  AreaBalanceTableComponent,
  RegionsTableComponent,
  RegionCardInfoComponent,
  RegionAreasTableComponent,
  RegionBalanceHeaderComponent,
  RegionBalanceTableComponent,
  MetersAllTableComponent,
  RegionBalanceTableComponent,
  MetersFileUploadTableComponent,
  RecoveyEnergyComponent,
  CopyButtonComponent,
  DashBOardFilterComponent,
  ReadingTableComponent,
  DownloadPDFComponent,
  GroupInfoCardComponent,
  AppLastCommandCard,
  ScheduledCommandsTableComponent,
  AddRoutineStepComponent,
  RoutineDetailsCardComponent,
  VoltageAlarmsTableComponent,
  CronEditorComponent,
  DicFicImportTableComponent,
  NavHorizontalComponent,
  ClockTableComponent,
  ParameterizeTicketTableComponent,
  ActiveCalendarTableComponent,
  DmcrSwitchTableComponent,
  BillingPeriodTableComponent,
  CardCommandComponent,
  MeterDMCRProfileTableComponent,
  FiscalComponent,
  DemandAndGeneralReadingsComponent,
  GeneralReadingsComponent,
  AuditQualityIndicatorsComponent,
  ConsumerInfoComponent
];

@NgModule({
  imports: [
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    MatIconModule,
    MatChipsModule,
    CommonModule,
    RouterModule,
    LayoutsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SearchModule,
    PerfectScrollbarModule,
    NgbModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    DragDropModule,
    NgSelectModule,
    UiSwitchModule,
    NgxEchartsModule,
    LeafletModule.forRoot(),
    LeafletMarkerClusterModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,
    CronEditorModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatRadioModule,
    TranslateModule,
    MatInputModule,
    NgbTooltipModule,
    MatGridListModule

  ],
  declarations: components,
  exports: [TranslateModule, LayoutsModule, ...components],
  providers: [
    NgSelectConfig,
    ɵs,
    NgxSpinnerService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
