import { ITransformerDTO } from "./TransformerDTO";
import { ModemDTO } from "./ModemDTO";
import { SmcDTO } from "./SmcDTO";
import { RateTypeDTO } from "./RateTypeDTO";
import { MeterModelDTO } from "./MeterModelDTO";
import { KeysDTO } from "./KeysDTO";
import { TagsDTO } from "./TagsTDO";

import { CommandStatus } from "src/app/core/models/CommandDTO";
import { FirmwareDTO } from "./FirmwareDTO";
import { RegistersDTO } from "./RegistersDTO";
import { Utils } from "src/app/shared/utils";

export interface MinimumMeter {
  serial: string;
  installation: string;
  online: boolean;
  accountantStatus: number;
}

export enum MeterPhase {
  Monofásico = 1,
  Bifásico = 2,
  Trifásico = 3,
}

export enum MeterType {
  Conventional = 0,
  ConventionalOptional = 1,
  Irrigating = 2,
  IrrigatingOptional = 3,
  MiniMicroConventional = 4,
  MiniMicroOptional = 5,
  WhiteRateConventional = 6,
  WhiteRateOptional = 7,
}

export enum FrozenDataCRCTemplate {
  Eletra = 2082884361, // 0x7c264b09,
  Eletra_Register_Status = 681873838, // 0x28a491ae,
  Nasen = 4255808498, // 0xfdaa7bf2,
  Siemens = 5983040, // 0x005B4B40,
  Nansen_Tariffs = 942298144, // 0x382A5420,
  WAISON_AMETER300 = 1029086717, //0x3D569DFD
  EASY_TRAFO = 1, //0x00000001,
  NANSEN_TARIFFS = 942298144, //0x382A5420,
  WAISON_CEMIG_PROFILE2 = 1612559923, //0x601DB633
  WAISON_CEMIG_EQTL = 1561354382, //0x5D10608E,
  ELETRA_ZEUS_NG = 1477662770, //0x58135832
}

export enum MassMemoryCRCTemplate {
  ELETRA_ZEUS_8021 = 3883743599, // 0xe77d396f,
  EASY_TRAFO = 1,
  ELETRA_ZEUS_8021_REGISTER_STATUS = 3537633278, // 0xd2dbfffe,
  ELETRA_ZEUS_8021_v1 = 754777579, // 0x2cfcfdeb,
  ELETRA_ZEUS_8021_REGISTER_STATUS_INCREMENTAL_ENERGY = 1406398462, // 0x53d3effe,
  ELETRA_ZEUS_8023_REGISTER_STATUS_INCREMENTAL_ENERGY = 4086236875, // 0xF38F06CB,
  ELETRA_ZEUS_8022 = 4272912119, // 0xfeaf76f7,
  ELETRA_ZEUS_8022_V1 = 1043194111, // 0x3e2de0ff,
  ELETRA_ZEUS_8022_REGISTER_STATUS = 3220175927, // 0xbfeffc37,
  ELETRA_ZEUS_8023 = 3549265791, // 0xd38d7f7f,
  NANSEN_NSX_113_I = 3889551671, // 0xe7d5d937,
  NANSEN_NSX_113_I_2 = 1466586410, // 0x576A552A,
  NANSEN_NSX_P213_I = 2645277032, // 0x9DABB968,
  NANSEN_NSX_P213_I_2 = 2329344090, // 0x8AD6F85A,
  NANSEN_NSX_P114_I = 2237350971, // 0x855b443b,
  NANSEN_NSX_P114_I_2 = 3246644273, // 0xC183DC31,
  SIEMENS_WAISON_IM_100 = 2607279148, // 0x9b67ec2c,
  SIEMENS_WAISON_IM_300 = 721050035, // 0x2afa59b3,
  NANSEN_COMMON = 330472406, // 0x13B29BD6,
  Nansen_Instantaneous = 2791852824, // 0xA6684B18
  Nansen_Profile_1 = 425649002, // 0x195EE36A,
  NANSEN_EDP_30 = 1026672489, // 0X3D31C769
  NANSEN_PROFILE_1_MONO = 1677270232, // 0x63F91CD8,
  NANSEN_PROFILE_1_BI = 2980613131, // 0xB1A88C0B,
  NANSEN_PROFILE_1_TRI = 1816264711, // 0x6C420007,
  WAISON_AMETER300 = 2729676603, // 0xA2B38F3B,
  NANSEN_BI = 2744731471, //0xA399474F,
  NANSEN_TRI = 2840975828, // 0xA955D9D4,
  NANSEN_INDIRECT = 536324009, //0x1FF7A7A9,
  NANSEN_INSTANTANEOUS = 2791852824, //0xA6684B18,
  NANSEN_NSX_112_I_2 = 425649002, //0x195EE36A,
  NANSEN_EDP = 3625412683, //0xD817684B,
  NANSEN_EDP_30_200 = 1026672489, //0x3D31C769,
  NANSEN_EDP_MASS_MEMORY = 3625412683, //0xD817684B,
  NANSEN_MONO = 2715475246, //0xA1DADD2E,
  NANSEN_EDP_BI = 2744731471, //0xA399474F,
  NANSEN_EDP_TRI = 2840975828, //0xA955D9D4,
  WASION_CEMIG_MONO_DEFAULT = 907565059, //0x36185803,
  WASION_CEMIG_TRI_DEFAULT = 3970879715, //0xECAED0E3,
  WASION_MONO_EQTL = 81966537, //0x04E2B5C9, //
  WASION_TRI_EQTL = 3537170263, //0xD2D4EF57
  WASION_CEMIG_MONO = 1347996779, //0x5058CC6B
  NANSEN_CONSTANTA_PROFILE_1 =  20951165, //0x013FB07D
  ELETRA_ZEUS_NG = 3412997746, //0xCB6E3672
  ZEUS_PROFILE_1 = 1966169312, //0x75315CE0
  ZEUS_PROFILE_OG = 3722051575,//0xDDD9FFF7
}

export enum MeterStatus {
  OFF = 0,
  ON = 1,
  NO_MODEM = 2,
  NO_COMISSIONED = 3,
}

export enum MeterIdentifier {
  I,
  R,
}

export enum MeterManufacturer {
  Eletra = "ELETRA",
  Nansen = "NANSEN",
  Siemens = "WAISON",
  Ello = "ELLO",
}

export enum CommunicationStatus {
  NO_INFORMATION = 0,
  SUCCESS = 1,
  BUSY = 2,
  COMMUNICATION_FAILED = 3,
}

export enum AccountStatus {
  DISABLED = 0,
  ACTIVATED = 1,
  NO_RELE = 2,
  NO_INFORMATION = 3,
}

export enum MeterConnectionPhase {
  A,
  B,
  C,
  AN,
  BN,
  CN,
  AB,
  AC,
  BC,
  ABN,
  ACN,
  BCN,
  ABC,
  ABCN,
  A3N,
  B3N,
  C3N,
  B3,
  A3,
  C3,
  A2N,
  B2N,
  C2N,
}
const CONECTIONPHASE = {
  A: 0,
  B: 1,
  C: 2,
  AN: 3,
  BN: 4,
  CN: 5,
  AB: 6,
  AC: 7,
  BC: 8,
  ABN: 9,
  ACN: 10,
  BCN: 11,
  ABC: 12,
  ABCN: 13,
  A3N: 14,
  B3N: 15,
  C3N: 16,
  B3: 17,
  A3: 18,
  C3: 19,
  A2N: 20,
  B2N: 21,
  C2N: 22,
};

export interface IMeterDTO {
  hasTransparent: boolean;
  serial: string;
  position: number;
  installation: string;
  latitude: number;
  longitude: number;
  prefix: string;
  billingConstant: number;
  tcRatio: string;
  tpRatio: string;
  registrars: string;
  tli: string;
  identifier: MeterIdentifier;
  phase: MeterPhase;
  connectionPhase: MeterConnectionPhase;
  accountantStatus: AccountStatus;
  meterModel: MeterModelDTO;
  rateType: RateTypeDTO;
  smc: SmcDTO;
  modem: ModemDTO;
  keys: KeysDTO;
  commandQueue: number;
  updatedAt: Date;
  status: boolean;
  firmwareVersionDto: FirmwareDTO;
  meterRegisters: RegistersDTO[];
  onlineTimeOutInMinutes: number;
  online: boolean;
  lastSeen: Date;
  orig: string;
  checked: boolean;
  meterType: MeterType;
  meterStatus: MeterStatus;
  meterActiveEnergyDTO: MeterActiveEnergyDTO;
  communicationStatus: CommunicationStatus;
  command: CommandStatus;
  ownsSmc: boolean;
  comissioned: boolean;
  tags: TagsDTO[];
  templateCrcFrozenData: FrozenDataCRCTemplate;
  templateCrcMassMemory: MassMemoryCRCTemplate;
  transformer: ITransformerDTO;
  transformerAttached: ITransformerDTO;
}

export interface IMeterComissionedDTO {
  hasTransparent: boolean;
  serial: string;
  installation: string;
  latitude: number;
  longitude: number;
  prefix: string;
  billingConstant: number;
  tcRatio: string;
  tpRatio: string;
  registrars: string;
  tli: string;
  identifier: MeterIdentifier;
  phase: MeterPhase;
  connectionPhase: MeterConnectionPhase;
  accountantStatus: AccountStatus;
  meterModel: MeterModelDTO;
  rateType: RateTypeDTO;
  smc: SmcDTO;
  modem: ModemDTO;
  keys: KeysDTO;
  commandQueue: number;
  updatedAt: Date;
  status: boolean;
  firmwareVersionDto: FirmwareDTO;
  meterRegisters: RegistersDTO[];
  onlineTimeOutInMinutes: number;
  online: boolean;
  lastSeen: Date;
  orig: string;
  checked: boolean;
  meterType: MeterType;
  meterStatus: MeterStatus;
  communicationStatus: CommunicationStatus;
  command: CommandStatus;
  meterActiveEnergyDTO: MeterActiveEnergyDTO;
  ownsSmc: boolean;
  comissioned: boolean;
  tags: TagsDTO[];
  templateCrcFrozenData: FrozenDataCRCTemplate;
  templateCrcMassMemory: MassMemoryCRCTemplate;
  position: number;
  transformer: ITransformerDTO;
  transformerAttached: ITransformerDTO;
}

export interface IMeterNotComissionedDTO {
  hasTransparent: boolean;
  serial: string;
  installation: string;
  latitude: number;
  longitude: number;
  prefix: string;
  billingConstant: number;
  tcRatio: string;
  tpRatio: string;
  registrars: string;
  tli: string;
  identifier: MeterIdentifier;
  phase: MeterPhase;
  connectionPhase: MeterConnectionPhase;
  accountantStatus: AccountStatus;
  meterModel: MeterModelDTO;
  rateType: RateTypeDTO;
  smc: SmcDTO;
  modem: ModemDTO;
  keys: KeysDTO;
  commandQueue: number;
  updatedAt: Date;
  status: boolean;
  firmwareVersionDto: FirmwareDTO;
  meterRegisters: RegistersDTO[];
  onlineTimeOutInMinutes: number;
  online: boolean;
  lastSeen: Date;
  orig: string;
  checked: boolean;
  meterType: MeterType;
  meterStatus: MeterStatus;
  communicationStatus: CommunicationStatus;
  command: CommandStatus;
  meterActiveEnergyDTO: MeterActiveEnergyDTO;
  ownsSmc: boolean;
  comissioned: boolean;
  tags: TagsDTO[];
  templateCrcFrozenData: FrozenDataCRCTemplate;
  templateCrcMassMemory: MassMemoryCRCTemplate;
  position: number;
  transformer: ITransformerDTO;
  transformerAttached: ITransformerDTO;
}

export interface IMeterUnregisteredDTO {
  serial: string;
  meterPhase: MeterPhase;
  deviceEui: string;
  updatedAt: Date;
}

export class ActiveEnergyDto {
  directEnergy: string;
  reverseEnergy: string;
  readingTime: string;
  updatedAt: Date;
}

export class MeterActiveEnergyDTO {
  directEnergy: string;
  reverseEnergy: string;
  readingTime: string;
  meter: MeterDTO;
}

export class MeterDTO implements IMeterDTO {
  public hasTransparent: boolean;
  public serial: string;
  public installation: string;
  public latitude: number;
  public longitude: number;
  public prefix: string;
  public billingConstant: number;
  public tcRatio: string;
  public networkType?: number;
  public networkTech?: number;
  public tpRatio: string;
  public registrars: string;
  public tli: string;
  public identifier: MeterIdentifier;
  public phase: MeterPhase;
  public connectionPhase: MeterConnectionPhase;
  public accountantStatus: AccountStatus;
  public meterModel: MeterModelDTO;
  public rateType: RateTypeDTO;
  public smc: SmcDTO;
  public modem: ModemDTO;
  public keys: KeysDTO;
  public commandQueue: number;
  public updatedAt: Date;
  public status: boolean;
  public firmwareVersionDto: FirmwareDTO;
  public firmwareVersion: FirmwareDTO;
  public meterRegisters: RegistersDTO[];
  public online: boolean;
  public lastSeen: Date;
  public onlineTimeOutInMinutes: number;
  public orig: string;
  public checked: boolean;
  public meterType: MeterType;
  public meterStatus: MeterStatus;
  public communicationStatus: CommunicationStatus;
  public command: CommandStatus;
  public meterActiveEnergyDTO: MeterActiveEnergyDTO;
  public ownsSmc: boolean;
  public comissioned: boolean;
  public tags: TagsDTO[];
  public templateCrcFrozenData: FrozenDataCRCTemplate;
  public templateCrcMassMemory: MassMemoryCRCTemplate;
  public position: number;
  public transformer: ITransformerDTO;
  public transformerAttached: ITransformerDTO;
  public qeePeriod?: number;
  public longOutVoltagePeriod?: number;
  public startDateDaylightSaving?: string;
  public endDateDaylightSaving?: string;
  public deviationDaylightSaving?: number
  public isEnableDaylightSaving?:  boolean;
  constructor() {
    this.phase = MeterPhase.Monofásico;
    this.hasTransparent = false;
    this.checked = false;
    this.serial = "";
    this.installation = "";
    this.rateType = new RateTypeDTO();
    this.meterModel = new MeterModelDTO();
    this.orig = "mdc cadastro";
    this.tags = [];
    this.meterRegisters = [];
  }
  public static getConnectionPhase(phase: any) {
    return CONECTIONPHASE[phase];
  }
  public static getStatusValue(device: MeterDTO): string {
    if (device) {
      switch (device.meterStatus) {
        case MeterStatus.OFF:
          return "Offline";
        case MeterStatus.ON:
          return "Online";
        case MeterStatus.NO_MODEM:
          return "Sem Modem";
        case MeterStatus.NO_COMISSIONED:
          return "Não Comissionado";
        default:
          return "Status Não Encotrado";
      }
    }
  }

  public static getPhase(phase: string) {
    return MeterPhase[phase];
  }

  static getComunicationStatus(comunicationStatus: boolean): string {
    return comunicationStatus ? "Online" : "Offline";
  }

  static getComunicationStatusClass(comunicationStatus: boolean): string {
    return comunicationStatus
      ? "badge badge-success mr-1"
      : "badge badge-danger mr-1";
  }

  static getTagsName(device: MeterDTO, index: number) {
    return device.tags[index].keyword;
  }

  static getTagsColor(device: MeterDTO, index: number) {
    return device.tags[index].colorHexCode;
  }

  static meterIncludesTag(tag: TagsDTO, device: MeterDTO) {
    for (const tags in device.tags) {
      if (device.tags[tags].visibleId === tag.visibleId) {
        return { include: true, index: tags };
      }
    }
  }

  static getMetersSerial(meters: MeterDTO[]): string[] {
    const metersSerial: string[] = [];
    meters.forEach((meter) => {
      metersSerial.push(meter.serial);
    });
    return metersSerial;
  }

  static tagListIncludeTag(tagsList: TagsDTO[], tag: TagsDTO) {
    for (const tags in tagsList) {
      if (tagsList[tags].visibleId === tag.visibleId) {
        return { include: true, index: tags };
      }
    }
    return false;
  }

  static getMeterIndex(meterSerial: string, meterList: MeterDTO[]): any {
    if (!meterList) {
      return;
    }
    return meterList.findIndex((meter) => meter.serial === meterSerial);
  }

  public static getFrozenDataCRCTemplate(
    templateCrcFrozenData: FrozenDataCRCTemplate,
  ) {
    switch (templateCrcFrozenData) {
      case FrozenDataCRCTemplate.Eletra:
        return "ELETRA" + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.Eletra_Register_Status:
        return "Registro do Status Eletra" + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.Siemens:
        return "WAISON" + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.Nasen:
        return "NANSEN" + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.Nansen_Tariffs:
        return "NANSEN Profile 2" + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.WAISON_AMETER300:
        return 'WAISON_AMETER300' + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.EASY_TRAFO:
        return 'Easy Trafo' + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.WAISON_CEMIG_PROFILE2:
        return 'WASION PROFILE2' + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
      case FrozenDataCRCTemplate.WAISON_CEMIG_EQTL:
        return 'AMeter' + ` (${Utils.decimalToHexString(
          templateCrcFrozenData,
        )})`;
        case FrozenDataCRCTemplate.ELETRA_ZEUS_NG:
          return 'ZEUS NG' + ` (${Utils.decimalToHexString(
            templateCrcFrozenData,
          )})`;
      default:
        return `Template desconhecido: ${Utils.decimalToHexString(
          templateCrcFrozenData,
        )}`;
    }
  }

  public static getMassMemoryCRCTemplate(
    templateCrcMassMemory: MassMemoryCRCTemplate,
  ) {
    switch (templateCrcMassMemory) {
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8021:
        return "ZEUS 8021 Sem Status do Registro" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8021_REGISTER_STATUS:
        return "ZEUS 8021 Com Status do Registro" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8021_REGISTER_STATUS_INCREMENTAL_ENERGY:
        return "ZEUS 8021 Com Status do Registro e Energia Incremental" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8023_REGISTER_STATUS_INCREMENTAL_ENERGY:
        return "ZEUS 8023 Com Status do Registro e Energia Incremental" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8021_v1:
        return "ZEUS 8021  V1" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8022:
        return "ZEUS 8023 Bifásico Sem Status do Registro" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8022_REGISTER_STATUS:
        return "ZEUS 8023 Bifásico Com Status do Registro" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8022_V1:
        return "ZEUS 8023 V1" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_8023:
        return "ZEUS 8023 Trifásico Sem Status do Registro" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_NSX_113_I:
        return "NANSEN NSX 113 I" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_NSX_113_I_2:
        return "NANSEN NSX 113 I 2º" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_NSX_P213_I:
        return "NANSEN NSX 213 I" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_NSX_P213_I_2:
        return "NANSEN NSX 213 I 2º" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_NSX_P114_I:
        return "NANSEN NSX 114 I" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_NSX_P114_I_2:
        return "NANSEN NSX 114 I 2º" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.SIEMENS_WAISON_IM_100:
        return "WAISON 100" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.SIEMENS_WAISON_IM_300:
        return "WAISON 300" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_COMMON:
        return "NANSEN" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.Nansen_Instantaneous:
        return "NANSEN INSTANTÂNEO" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.Nansen_Profile_1:
        return "NANSEN Profile 1" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_EDP_30:
        return "NANSEN EDP 30(200)" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_PROFILE_1_BI:
        return "NANSEN PROFILE 1 BI" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_PROFILE_1_MONO:
        return "NANSEN PROFILE 1 MONO" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_PROFILE_1_TRI:
        return "NANSEN PROFILE 1 TRI" + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.WAISON_AMETER300:
        return 'WAISON_AMETER300' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.EASY_TRAFO:
        return 'Easy Trafo' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_EDP:
        return 'Nansen' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_BI:
        return 'NANSEN BI' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_EDP_TRI:
        return 'NANSEN TRI' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_INDIRECT:
        return 'NANSEN INDIRETO' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.NANSEN_MONO:
        return 'NANSEN MONO' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.WASION_CEMIG_MONO:
        return 'WASION MONO' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.WASION_CEMIG_MONO_DEFAULT:
        return 'WASION MONO PADRÃO' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.WASION_CEMIG_TRI_DEFAULT:
        return 'WASION TRI PADRÃO' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.WASION_MONO_EQTL:
        return 'AMeter Monofásico' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.WASION_TRI_EQTL:
        return 'AMeter TRI' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory
        )})`;
      case MassMemoryCRCTemplate.NANSEN_CONSTANTA_PROFILE_1:
        return 'NANSEN CONTANTA PROFILE 1' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ELETRA_ZEUS_NG:
        return 'ZEUS NG' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ZEUS_PROFILE_1:
        return 'ZEUS PROFILE 1' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      case MassMemoryCRCTemplate.ZEUS_PROFILE_OG:
        return 'ZEUS OG' + ` (${Utils.decimalToHexString(
          templateCrcMassMemory,
        )})`;
      default:
        return `Template desconhecido: ${Utils.decimalToHexString(
          templateCrcMassMemory,
        )}`;
    }
  }

  public static getCommunicationStatusClass(device): string {
    if (device) {
      switch (device.communicationStatus) {
        case CommunicationStatus.NO_INFORMATION:
          return "badge badge-info mr-1";
        case CommunicationStatus.BUSY:
          return "badge badge-warning mr-1";
        case CommunicationStatus.COMMUNICATION_FAILED:
          return "badge badge-danger mr-1";
        case CommunicationStatus.SUCCESS:
          return "badge badge-success mr-1";
      }
    }
  }

  public static getRelayStatusValue(accountantStatus: AccountStatus): string {
    switch (accountantStatus) {
      case AccountStatus.DISABLED:
        return "Desativado";
      case AccountStatus.ACTIVATED:
        return "Ativado";
      case AccountStatus.NO_RELE:
        return "Não Possui Relé";
      case AccountStatus.NO_INFORMATION:
        return "Sem informação";
    }
  }

  public static getModels(device): string {
    if (device) {
      switch (device.meterModel) {
        case AccountStatus.DISABLED:
          return "Desativado";
        case AccountStatus.NO_RELE:
          return "Não possui relé";
        case AccountStatus.ACTIVATED:
          return "Ativado";
        case AccountStatus.NO_INFORMATION:
          return "Sem informação";
      }
    }
  }

  public static getManufacturer(device): string {
    if (device) {
      switch (device.meterManufacturer) {
        case MeterManufacturer.Eletra:
          return "Eletra";
        case MeterManufacturer.Siemens:
          return "Waison";
        case MeterManufacturer.Nansen:
          return "Nansen";
        case MeterManufacturer.Ello:
          return "Ello";
      }
    }
  }

  public static getTypeValue(device): string {
    if (device) {
      switch (device.meterType) {
        case MeterType.Conventional:
          return "Convencional";
        case MeterType.ConventionalOptional:
          return "Opcional Convencional";
        case MeterType.Irrigating:
          return "Irrigante";
        case MeterType.IrrigatingOptional:
          return "Opcional Irrigante";
        case MeterType.MiniMicroConventional:
          return "Convencional Mini Micro";
        case MeterType.MiniMicroOptional:
          return "Opcional Mini Micro";
        case MeterType.WhiteRateConventional:
          return "Convencional Taxa de Branco";
        case MeterType.WhiteRateOptional:
          return "Optional Taxa de Branco";
      }
    }
  }

  public static getPhaseValue(device: MeterDTO): string {
    if (device) {
      switch (device.phase) {
        case MeterPhase.Monofásico:
          return "Monofásico";
        case MeterPhase.Bifásico:
          return "Bifásico";
        case MeterPhase.Trifásico:
          return "Trifásico";
      }
    }
  }

  public static getStatusClass(device): string {
    if (device) {
      switch (device.meterStatus) {
        case MeterStatus.OFF:
          return "badge badge-danger mr-1";
        case MeterStatus.ON:
          return "badge badge-success mr-1";
        case MeterStatus.NO_MODEM:
          return "badge badge-warning mr-1";
        case MeterStatus.NO_COMISSIONED:
          return "badge badge-dark mr-1";
        default:
          return "badge badge-warning mr-1";
      }
    }
  }

  public static getMAnufacuterName(meter: MeterDTO) {
    if (meter && meter.meterModel && meter.meterModel.manufacturer) {
      return meter.meterModel.manufacturer.name === "SIEMENS"
        ? "WAISON"
        : meter.meterModel.manufacturer.name.toUpperCase();
    }
  }

  public static getRelayStatusClass(device): string {
    if (device) {
      switch (device.accountantStatus) {
        case AccountStatus.DISABLED:
          return "badge badge-danger mr-1";
        case AccountStatus.NO_RELE:
          return "badge badge-warning mr-1";
        case AccountStatus.ACTIVATED:
          return "badge badge-success mr-1";
        case AccountStatus.NO_INFORMATION:
          return "badge badge-info mr-1";
      }
    }
  }
}

export class MeterComissionedDTO implements IMeterComissionedDTO {
  public hasTransparent: boolean;
  public position: number;
  public serial: string;
  public installation: string;
  public latitude: number;
  public longitude: number;
  public prefix: string;
  public billingConstant: number;
  public tcRatio: string;
  public tpRatio: string;
  public registrars: string;
  public tli: string;
  public identifier: MeterIdentifier;
  public phase: MeterPhase;
  public connectionPhase: MeterConnectionPhase;
  public accountantStatus: AccountStatus;
  public meterModel: MeterModelDTO;
  public rateType: RateTypeDTO;
  public smc: SmcDTO;
  public modem: ModemDTO;
  public keys: KeysDTO;
  public commandQueue: number;
  public updatedAt: Date;
  public status: boolean;
  public firmwareVersionDto: FirmwareDTO;
  public meterRegisters: RegistersDTO[];
  public online: boolean;
  public lastSeen: Date;
  public onlineTimeOutInMinutes: number;
  public orig: string;
  public checked: boolean;
  public meterType: MeterType;
  public meterStatus: MeterStatus;
  public communicationStatus: CommunicationStatus;
  public command: CommandStatus;
  public meterActiveEnergyDTO: MeterActiveEnergyDTO;
  public ownsSmc: boolean;
  public comissioned: boolean;
  public tags: TagsDTO[];
  public templateCrcFrozenData: FrozenDataCRCTemplate;
  public templateCrcMassMemory: MassMemoryCRCTemplate;
  public transformer: ITransformerDTO;
  public transformerAttached: ITransformerDTO;
}

export class MeterNotComissionedDTO implements IMeterNotComissionedDTO {
  public hasTransparent: boolean;
  public position: number;
  public serial: string;
  public installation: string;
  public latitude: number;
  public longitude: number;
  public prefix: string;
  public billingConstant: number;
  public tcRatio: string;
  public tpRatio: string;
  public registrars: string;
  public tli: string;
  public identifier: MeterIdentifier;
  public phase: MeterPhase;
  public connectionPhase: MeterConnectionPhase;
  public accountantStatus: AccountStatus;
  public meterModel: MeterModelDTO;
  public rateType: RateTypeDTO;
  public smc: SmcDTO;
  public modem: ModemDTO;
  public keys: KeysDTO;
  public commandQueue: number;
  public updatedAt: Date;
  public status: boolean;
  public firmwareVersionDto: FirmwareDTO;
  public meterRegisters: RegistersDTO[];
  public online: boolean;
  public lastSeen: Date;
  public onlineTimeOutInMinutes: number;
  public orig: string;
  public checked: boolean;
  public meterType: MeterType;
  public meterStatus: MeterStatus;
  public communicationStatus: CommunicationStatus;
  public command: CommandStatus;
  public meterActiveEnergyDTO: MeterActiveEnergyDTO;
  public ownsSmc: boolean;
  public comissioned: boolean;
  public tags: TagsDTO[];
  public templateCrcFrozenData: FrozenDataCRCTemplate;
  public templateCrcMassMemory: MassMemoryCRCTemplate;
  public transformer: ITransformerDTO;
  public transformerAttached: ITransformerDTO;
}

export class MeterUnregisteredDTO implements IMeterUnregisteredDTO {
  public serial: string;
  public meterPhase: MeterPhase;
  public deviceEui: string;
  public updatedAt: Date;

  public static getPhaseValue(meterPhase: MeterPhase): string {
    switch (meterPhase) {
      case MeterPhase.Monofásico:
        return "Monofásico";
      case MeterPhase.Bifásico:
        return "Bifásico";
      case MeterPhase.Trifásico:
        return "Trifásico";
    }
  }
}
