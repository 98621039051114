export interface ConsumerUnit{
  id: string;
  deletedAt?: string;
  consumerUnitCode?: string;
  fullName?: string;
  clientNumber?: string;
  fullAddress?: string;
  locationCode?: string;
  locationTypeCode?: string;
  freeCode?: string;
  stageCode?: string;
  billingEventCode?: string;
  regionCode?: string;
  districtCode?: string;
  sectionCode?: string;
  billingTariffTypeCode?: string;
  billingTensionGroupCode?: string;
  billingSubgroupCode?: string;
  maxDemandContractedPT?: string;
  maxDemandContractedFP?: string;
  maxDemandContractedTP?: string;
  situationCode?: SituationCode;
  situationDate?: string;
  consumptionClassCode?: string;
  disconnectionRelayStatus?: DisconnectionRelayStatus;
  meterReadingPlanStatus?: string;
  communicationDescription?: string;
  streetCode?: string;
  neighborhoodCode?: string;
  neighborhoodDescription?: string;
  primaryClassCode?: string;
  primaryClassDescription?: string;
  meterId?: string;
  equipmentNumber?: string;
}

export class ConsumerDTO implements ConsumerUnit{
  id: string;
  deletedAt?: string;
  consumerUnitCode?: string;
  fullName?: string;
  clientNumber?: string;
  fullAddress?: string;
  locationCode?: string;
  locationTypeCode?: string;
  freeCode?: string;
  stageCode?: string;
  billingEventCode?: string;
  regionCode?: string;
  districtCode?: string;
  sectionCode?: string;
  billingTariffTypeCode?: string;
  billingTensionGroupCode?: string;
  billingSubgroupCode?: string;
  maxDemandContractedPT?: string;
  maxDemandContractedFP?: string;
  maxDemandContractedTP?: string;
  situationCode?: SituationCode;
  situationDate?: string;
  consumptionClassCode?: string;
  disconnectionRelayStatus?: DisconnectionRelayStatus;
  meterReadingPlanStatus?: string;
  communicationDescription?: string;
  streetCode?: string;
  neighborhoodCode?: string;
  neighborhoodDescription?: string;
  primaryClassCode?: string;
  primaryClassDescription?: string;
  meterId?: string; // Required string
  equipmentNumber?: string;

  constructor(
    id: string,
    deletedAt?: string,
    consumerUnitCode?: string,
    fullName?: string,
    clientNumber?: string,
    fullAddress?: string,
    locationCode?: string,
    locationTypeCode?: string,
    freeCode?: string,
    stageCode?: string,
    billingEventCode?: string,
    regionCode?: string,
    districtCode?: string,
    sectionCode?: string,
    billingTariffTypeCode?: string,
    billingTensionGroupCode?: string,
    billingSubgroupCode?: string,
    maxDemandContractedPT?: string,
    maxDemandContractedFP?: string,
    maxDemandContractedTP?: string,
    situationCode?: SituationCode,
    situationDate?: string,
    consumptionClassCode?: string,
    disconnectionRelayStatus?: DisconnectionRelayStatus,
    meterReadingPlanStatus?: string,
    communicationDescription?: string,
    streetCode?: string,
    neighborhoodCode?: string,
    neighborhoodDescription?: string,
    primaryClassCode?: string,
    primaryClassDescription?: string,
    meterId?: string,
    equipmentNumber?: string
  ) {
    this.id = id;
    this.deletedAt = deletedAt;
    this.consumerUnitCode = consumerUnitCode;
    this.fullName = fullName;
    this.clientNumber = clientNumber;
    this.fullAddress = fullAddress;
    this.locationCode = locationCode;
    this.locationTypeCode = locationTypeCode;
    this.freeCode = freeCode;
    this.stageCode = stageCode;
    this.billingEventCode = billingEventCode;
    this.regionCode = regionCode;
    this.districtCode = districtCode;
    this.sectionCode = sectionCode;
    this.billingTariffTypeCode = billingTariffTypeCode;
    this.billingTensionGroupCode = billingTensionGroupCode;
    this.billingSubgroupCode = billingSubgroupCode;
    this.maxDemandContractedPT = maxDemandContractedPT;
    this.maxDemandContractedFP = maxDemandContractedFP;
    this.maxDemandContractedTP = maxDemandContractedTP;
    this.situationCode = situationCode;
    this.situationDate = situationDate;
    this.consumptionClassCode = consumptionClassCode;
    this.disconnectionRelayStatus = disconnectionRelayStatus;
    this.meterReadingPlanStatus = meterReadingPlanStatus;
    this.communicationDescription = communicationDescription;
    this.streetCode = streetCode;
    this.neighborhoodCode = neighborhoodCode;
    this.neighborhoodDescription = neighborhoodDescription;
    this.primaryClassCode = primaryClassCode;
    this.primaryClassDescription = primaryClassDescription;
    this.meterId = meterId;
    this.equipmentNumber = equipmentNumber;
  }

  public static getConnectionStatus(consumerInfo: ConsumerDTO): string {
    if (consumerInfo) {
      switch (consumerInfo.disconnectionRelayStatus) {
        case DisconnectionRelayStatus.DISCONNECTED:
          return "Desconectado";
        case DisconnectionRelayStatus.CONNECTED:
            return "Conectado";
        default:
          return "Status Não Encontrado";
      }
    }
  }

  public static getSituationCode(consumerInfo: ConsumerDTO): string {
    if (consumerInfo) {
      switch (consumerInfo.situationCode) {
        case SituationCode.INACTIVE:
          return "Inativo";
        case SituationCode.ACTIVE:
            return "Ativo";
        default:
          return "Status Não Encontrado";
      }
    }
  }
}


export enum DisconnectionRelayStatus{
  DISCONNECTED = 0,
  CONNECTED = 1
}

export enum SituationCode{
  INACTIVE = 0,
  ACTIVE = 1
}